import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractFilenameFromString'
})
export class ExtractFilenameFromStringPipe implements PipeTransform {

  transform(fileName: string): unknown {
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }

}


@Pipe({
  name: 'oldPathtoNewPath'
})
export class OldPathtoNewPathPipe implements PipeTransform {
  transform(url, str): unknown {
    return url.replace(/\/b\/.*\/o\//, `/b/${str}/o/`)
  }
}



// function replaceStorageId(url, str) {
//   return url.replace(/\/b\/.*\/o\//, `/b/${str}/o/`)
// }
