import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findItsAttendanceStat'
})
export class FindItsAttendanceStatPipe implements PipeTransform {

  transform(attendanceStats: Array<any>, dateMonthKey: any) {
    return attendanceStats.find((item) => {
      return item.key === dateMonthKey.field;
    });
  }

}
