import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterEmployeeHour'
})
export class FilterEmployeeHourPipe implements PipeTransform {

  transform(value: any[], dateKey:any): unknown {
    if(value && dateKey){
      const emp_hour = value.find(m=>m.dateKey===dateKey)
      return emp_hour.att.length
    }
    return null;
  }

  // transform(value: any[], key: any, headerKey?: boolean,field?:any): unknown {
  //   if (value) {
  //     if (headerKey) {
  //       const items= value.filter(m => m.headerKey === key)
  //       if(field) return sum(items,field)
  //       return items
  //     }
  //     return value.filter(m => m.programKey === key)
  //   }
  //   return null;
  // }
}
