import { Component, OnInit } from '@angular/core';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { Router } from '@angular/router';
import { Environment } from 'src/app/core/stores/environment';
import { Campus } from 'src/app/core/stores/campus.store';
import { UserStore } from 'src/app/core/stores/user.store';
import { MatDialog } from '@angular/material/dialog';
import { BaseStore } from 'src/app/core/stores/base.store';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  formFocus = false;
  yPosition = 'below';
  campusList = [];
  constructor(
    public auth: AuthStore,
    public env: Environment,
    private router: Router,
    private campus: Campus,
    public store: UserStore,
    private dialog: MatDialog,
    public baseStore: BaseStore,
    private ds: DataService,
  ) { }

  async ngOnInit() {
    this.store.fetchUser(doc=>{});
    // this.campus.fetchCampus()
    this.campusList = await this.baseStore.fetchList(this.ds.campusRef())
  }

  _onFocus(yes) {
    this.formFocus = yes;
  }

  logOut() {
    this.auth.signOut();
  }

  onSwitchCampus(item) {
    this.store.updateUserCampus(this.store.user, item);
  }

}
