import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-header-tabs',
  templateUrl: './header-tabs.component.html',
  styleUrls: ['./header-tabs.component.scss'],
})
export class HeaderTabsComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() buttonText: string;
  @Input() tabs: Array<any>;
  @Input() queryParams: any;
  @Output() onPress = new EventEmitter();

  constructor() {}

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  create() {
    this.onPress.emit();
  }
}
